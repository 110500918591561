import { Navbar } from 'flowbite-react';
import { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const AdminSecondaryNav = memo(() => {
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = (path: string) => location.pathname.startsWith(path);

  return (
    <Navbar className="border-b shadow-sm">
      <div className="container mx-auto flex flex-wrap items-center justify-between">
        <Navbar.Collapse>
          <Navbar.Link
            onClick={() => navigate('/admin/dashboard')}
            active={isActive('/admin/dashboard')}
            className="cursor-pointer"
          >
            Dashboard
          </Navbar.Link>
          <Navbar.Link
            onClick={() => navigate('/admin/departments')}
            active={isActive('/admin/departments')}
            className="cursor-pointer"
          >
            Departments
          </Navbar.Link>
          <Navbar.Link
            onClick={() => navigate('/admin/locations')}
            active={isActive('/admin/locations')}
            className="cursor-pointer"
          >
            Locations
          </Navbar.Link>
          <Navbar.Link
            onClick={() => navigate('/admin/jobs')}
            active={isActive('/admin/jobs')}
            className="cursor-pointer"
          >
            Jobs
          </Navbar.Link>
          <Navbar.Link
            onClick={() => navigate('/admin/actions')}
            active={isActive('/admin/actions')}
            className="cursor-pointer"
          >
            Actions
          </Navbar.Link>
          <Navbar.Link
            onClick={() => navigate('/admin/analytics/dashboard')}
            active={isActive('/admin/analytics')}
            className="cursor-pointer"
          >
            Analytics
          </Navbar.Link>
          <Navbar.Link
            onClick={() => navigate('/admin/workflow/approvals')}
            active={isActive('/admin/workflow')}
            className="cursor-pointer"
          >
            Workflow
          </Navbar.Link>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
});

AdminSecondaryNav.displayName = 'AdminSecondaryNav';

export default AdminSecondaryNav;
