import UserPool from "../../auth/UserPool";
import type { RequestConfig } from "./types";

/**
 * Get the current auth token from Cognito session
 */
const getAuthToken = async (): Promise<string | null> => {
    console.log('[Auth] Getting auth token...');
    const user = UserPool?.getCurrentUser();
    if (!user) {
        console.log('[Auth] No current user found');
        return null;
    }

    return new Promise((resolve) => {
        console.log('[Auth] Getting user session...');
        user.getSession((err: any, session: any) => {
            if (err) {
                console.error('[Auth] Error getting session:', err);
                resolve(null);
                return;
            }
            if (session) {
                const token = session.getIdToken().getJwtToken();
                console.log('[Auth] Token retrieved successfully');
                resolve(token);
            } else {
                console.log('[Auth] No session found');
                resolve(null);
            }
        });
    });
};

// Cache the token promise to avoid multiple concurrent session requests
let tokenPromise: Promise<string | null> | null = null;

/**
 * Get headers with auth token if available
 */
export const API_Headers: RequestConfig = {
    get headers() {
        // Start token fetch if not already in progress
        if (!tokenPromise) {
            tokenPromise = getAuthToken().then(token => {
                // Clear promise after completion to allow future fetches
                setTimeout(() => { tokenPromise = null; }, 0);
                return token;
            });
        }

        console.log('[Auth] Returning basic headers, auth token will be added by interceptor');
        // Return basic headers immediately
        // The auth token will be added by axios interceptor
        return {
            "Content-Type": "application/json",
            "Accept": "application/json"
        };
    }
};

// Add axios interceptor to include auth token
import axios from "axios";

axios.interceptors.request.use(async (config) => {
    console.log('[Auth] Request interceptor running for:', config.url);
    try {
        // Wait for token if fetch is in progress
        const token = await (tokenPromise || getAuthToken());
        if (token) {
            console.log('[Auth] Adding auth token to request');
            config.headers.Authorization = `Bearer ${token}`;
        } else {
            console.log('[Auth] No token available for request');
        }
    } catch (error) {
        console.error('[Auth] Error getting auth token:', error);
    }
    console.log('[Auth] Final request headers:', {
        ...config.headers,
        Authorization: config.headers.Authorization ? 'Bearer [token]' : 'None'
    });
    return config;
});
