import type { User, UserEntitlement } from '../../types/user';
import { API_ENDPOINTS as CORE_ENDPOINTS, Make_Authorized_API_Call } from '../core';
import type { APIResult } from '../core/types';

// Use full API endpoints from core
const API_ENDPOINTS = {
  USER: CORE_ENDPOINTS.USER.BASE,
  ENTITLEMENTS: CORE_ENDPOINTS.USER.ENTITLEMENTS
} as const;

export const userApi = {
  /**
   * Get user details including Super_Admin status
   */
  Get_User: async (email: string): Promise<APIResult<User>> => {
    return Make_Authorized_API_Call(
      `${API_ENDPOINTS.USER}?Email_Address=${encodeURIComponent(email)}`,
      'GET'
    );
  },

  /**
   * Get user entitlements including client/company access
   */
  Get_User_Entitlements: async (email: string): Promise<APIResult<UserEntitlement>> => {
    return Make_Authorized_API_Call(
      `${API_ENDPOINTS.ENTITLEMENTS}?Email_Address=${encodeURIComponent(email)}`,
      'GET'
    );
  }
};
