import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { API_ENDPOINTS, Make_Authorized_API_Call } from "../../../api/core/apiClient";
import { isSuccessResponse } from '../../../api/core/types';
import UserPool from '../../../auth/UserPool';
import type { User, UserEntitlement } from '../../../types';

// Define endpoints with type assertions following CI standards
const ENDPOINTS = {
    USER: {
        BASE: (API_ENDPOINTS as any).USER.BASE as string,
        ENTITLEMENTS: (API_ENDPOINTS as any).USER.ENTITLEMENTS as string
    }
} as const;

// Constants
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;
const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

class AuthAPI {
    // Backward compatibility methods
    readonly getUserEntitlements: (userEmail: string, retryCount?: number) => Promise<UserEntitlement[]>;
    readonly getUserData: (userEmail: string) => Promise<User[]>;
    readonly authenticate: (userEmail: string, password: string) => Promise<CognitoUser>;
    readonly getCurrentSession: () => Promise<CognitoUser>;
    readonly getCurrentUser: () => CognitoUser | null;
    readonly parseUserAttributes: (session: any) => {
        email: string;
    };
    readonly logout: () => void;

    constructor() {
        // Initialize backward compatibility methods
        this.getUserEntitlements = this.Get_User_Entitlements.bind(this);
        this.getUserData = this.Get_User_Data.bind(this);
        this.authenticate = this.Authenticate.bind(this);
        this.getCurrentSession = this.Get_Current_Session.bind(this);
        this.getCurrentUser = this.Get_Current_User.bind(this);
        this.parseUserAttributes = this.Parse_User_Attributes.bind(this);
        this.logout = this.Logout.bind(this);
    }

    async Get_User_Entitlements(User_Email: string, Retry_Count = 0): Promise<UserEntitlement[]> {
        try {
            const response = await Make_Authorized_API_Call<UserEntitlement>(
                `${ENDPOINTS.USER.ENTITLEMENTS}`,
                'GET',
                undefined,
                { params: { Email_Address: User_Email } }
            );

            if (!isSuccessResponse(response)) {
                throw new Error(response.message || response.error_details || 'Failed to get user entitlements');
            }

            return response.data.Records as unknown as UserEntitlement[];
        } catch (error) {
            if (Retry_Count < MAX_RETRIES) {
                await sleep(RETRY_DELAY * (Retry_Count + 1));
                return this.Get_User_Entitlements(User_Email, Retry_Count + 1);
            }
            throw error;
        }
    }

    async Get_User_Data(User_Email: string): Promise<User[]> {
        const response = await Make_Authorized_API_Call<User>(
            ENDPOINTS.USER.BASE,
            'GET',
            undefined,
            { params: { Email_Address: User_Email } }
        );

        if (!isSuccessResponse(response)) {
            throw new Error(response.message || response.error_details || 'Failed to get user data');
        }

        return response.data.Records;
    }

    async Authenticate(User_Email: string, Password: string): Promise<CognitoUser> {
        if (!UserPool) {
            throw new Error('Authentication system not properly configured');
        }

        const User = new CognitoUser({
            Username: User_Email,
            Pool: UserPool
        });

        const Auth_Details = new AuthenticationDetails({
            Username: User_Email,
            Password: Password
        });

        return new Promise((Resolve, Reject) => {
            User.authenticateUser(Auth_Details, {
                onSuccess: (Session) => {
                    const ID_Token = Session.getIdToken();
                    const Access_Token = Session.getAccessToken();
                    const Refresh_Token = Session.getRefreshToken();

                    // Token will be used automatically by Make_Authorized_API_Call
                    Resolve(User);
                },
                onFailure: (Err) => {
                    console.error('Authentication failed:', Err);
                    if (Err.name === 'NotAuthorizedException') {
                        Reject(new Error('Incorrect username or password'));
                    } else if (Err.name === 'UserNotFoundException') {
                        Reject(new Error('User does not exist'));
                    } else if (Err.name === 'InvalidParameterException') {
                        Reject(new Error('Invalid email or password format'));
                    } else {
                        Reject(Err);
                    }
                },
                newPasswordRequired: (userAttributes, requiredAttributes) => {
                    console.log('New password required');
                    Reject(new Error('New password required'));
                }
            });
        });
    }

    async Get_Current_Session(): Promise<CognitoUser> {
        const User = UserPool?.getCurrentUser();
        if (!User) {
            throw new Error('No current user');
        }

        return new Promise((Resolve, Reject) => {
            User.getSession((Err: any, Session: any) => {
                if (Err) {
                    console.error('Session error:', Err);
                    Reject(Err);
                } else if (Session) {
                    Resolve(User);
                } else {
                    Reject(new Error('No session found'));
                }
            });
        });
    }

    Get_Current_User(): CognitoUser | null {
        return UserPool?.getCurrentUser() || null;
    }

    Parse_User_Attributes(Session: any) {
        const ID_Token = Session.getIdToken();
        const Payload = JSON.parse(atob(ID_Token.getJwtToken().split('.')[1]));
        console.log('JWT Payload:', Payload);
        
        // We no longer need to parse super admin from JWT since we get it from the database
        return {
            email: Payload.email
        };
    }

    Logout(): void {
        const User = UserPool?.getCurrentUser();
        if (User) {
            User.signOut();
        }
    }
}

// Create and export instance
export const authApi = new AuthAPI();
