import type { Config_Action_Payload } from '../../types/action';
import { API_ENDPOINTS, Make_Authorized_API_Call } from '../core/apiClient';
import type { APIResult } from '../core/types';
import type { Action, Action_Params, Config_Action } from './types';

export const actionApi = {
    /**
     * Config Actions
     */
    Get_Action_Configs: async (Company_ID: string): Promise<APIResult<Config_Action>> => {
        return Make_Authorized_API_Call(
            `${API_ENDPOINTS.CONFIG.ACTIONS}?Company_ID=${Company_ID}`,
            'GET'
        );
    },

    Create_Action_Config: async (data: Config_Action_Payload): Promise<APIResult<Config_Action>> => {
        return Make_Authorized_API_Call(
            API_ENDPOINTS.CONFIG.ACTIONS,
            'POST',
            data
        );
    },

    Update_Action_Config: async (Action_ID: string, data: Config_Action_Payload): Promise<APIResult<Config_Action>> => {
        return Make_Authorized_API_Call(
            `${API_ENDPOINTS.CONFIG.ACTIONS}/${Action_ID}`,
            'PUT',
            data
        );
    },

    Delete_Action_Config: async (Action_ID: string): Promise<APIResult<Config_Action>> => {
        return Make_Authorized_API_Call(
            `${API_ENDPOINTS.CONFIG.ACTIONS}/${Action_ID}`,
            'DELETE'
        );
    },

    /**
     * Get actions
     */
    Get_Actions: async (params: Action_Params): Promise<APIResult<Action>> => {
        const queryParams = new URLSearchParams();
        
        // Add required parameters
        queryParams.append('Company_ID', params.Company_ID);
        
        // Add Active_Approver parameter (use Employee_ID as fallback)
        if (params.Active_Approver || params.Employee_ID) {
            queryParams.append('Active_Approver', params.Active_Approver || params.Employee_ID || '');
        }
        
        // Add optional parameters
        if (params.Action_Type) queryParams.append('Action_Type', params.Action_Type);

        return Make_Authorized_API_Call(
            `${API_ENDPOINTS.COMPANY.ACTION.PENDING}?${queryParams.toString()}`,
            'GET'
        );
    },

    /**
     * Get completed actions
     */
    Get_Completed_Actions: async (params: Action_Params): Promise<APIResult<Action>> => {
        const queryParams = new URLSearchParams();
        queryParams.append('Company_ID', params.Company_ID);
        if (params.Employee_ID) queryParams.append('Employee_ID', params.Employee_ID);

        return Make_Authorized_API_Call(
            `${API_ENDPOINTS.COMPANY.ACTION.COMPLETED}?${queryParams.toString()}`,
            'GET'
        );
    },

    /**
     * Get action details
     */
    Get_Action: async (params: Action_Params & { Action_ID: string }): Promise<APIResult<Action>> => {
        return Make_Authorized_API_Call(
            `${API_ENDPOINTS.COMPANY.ACTION.STATS}?Company_ID=${params.Company_ID}&Action_ID=${params.Action_ID}`,
            'GET'
        );
    },

    /**
     * Create action
     */
    Create_Action: async (data: Action_Params & { 
        Employee_ID: string;
        Action_Type: string;
        Description?: string;
        Due_Date?: string;
    }): Promise<APIResult<Action>> => {
        return Make_Authorized_API_Call(
            API_ENDPOINTS.COMPANY.ACTION.INITIATE,
            'POST',
            data
        );
    },

    /**
     * Update and advance action
     */
    Update_Action: async (params: Action_Params & { 
        Action_ID: string;
        Status?: string;
        Comments?: string;
    }): Promise<APIResult<Action>> => {
        return Make_Authorized_API_Call(
            API_ENDPOINTS.COMPANY.ACTION.ADVANCE,
            'PUT',
            params
        );
    },

    /**
     * Reject action
     */
    Reject_Action: async (params: Action_Params & { 
        Action_ID: string;
        Comments: string;
    }): Promise<APIResult<Action>> => {
        return Make_Authorized_API_Call(
            API_ENDPOINTS.COMPANY.ACTION.REJECT,
            'PUT',
            params
        );
    }
};
