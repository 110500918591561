import { API_ENDPOINTS, Make_Authorized_API_Call } from '../core/apiClient';
import type { AnalyticsParams, AttritionAnalysisResult } from './types';

// Define endpoints with type assertions following CI standards
const ENDPOINTS = {
    ATTRITION: {
        COMPANY: (API_ENDPOINTS as any).ANALYTICS.ATTRITION.COMPANY as string,
        DEPARTMENT: (API_ENDPOINTS as any).ANALYTICS.ATTRITION.DEPARTMENT as string,
        JOB: (API_ENDPOINTS as any).ANALYTICS.ATTRITION.JOB as string,
        LOCATION: (API_ENDPOINTS as any).ANALYTICS.ATTRITION.LOCATION as string
    },
    WAGE: {
        COMPANY: (API_ENDPOINTS as any).ANALYTICS.WAGE.COMPANY as string,
        DEPARTMENT: (API_ENDPOINTS as any).ANALYTICS.WAGE.DEPARTMENT as string,
        JOB: (API_ENDPOINTS as any).ANALYTICS.WAGE.JOB as string,
        LOCATION: (API_ENDPOINTS as any).ANALYTICS.WAGE.LOCATION as string
    },
    TENURE: {
        COMPANY: (API_ENDPOINTS as any).ANALYTICS.TENURE.COMPANY as string,
        DEPARTMENT: (API_ENDPOINTS as any).ANALYTICS.TENURE.DEPARTMENT as string,
        JOB: (API_ENDPOINTS as any).ANALYTICS.TENURE.JOB as string,
        LOCATION: (API_ENDPOINTS as any).ANALYTICS.TENURE.LOCATION as string
    }
} as const;

export const analyticsApi = {
    attrition: {
        Get_Company_Attrition_Analysis: async (params: AnalyticsParams): Promise<AttritionAnalysisResult> => {
            if (!params.Company_ID) {
                throw new Error('Company_ID is required');
            }

            return Make_Authorized_API_Call(
                `${ENDPOINTS.ATTRITION.COMPANY}?Company_ID=${params.Company_ID}`,
                'GET'
            );
        },

        Get_Location_Attrition_Analysis: async (params: AnalyticsParams & { Location_ID: string }): Promise<AttritionAnalysisResult> => {
            if (!params.Company_ID || !params.Location_ID) {
                throw new Error('Company_ID and Location_ID are required');
            }

            return Make_Authorized_API_Call(
                `${ENDPOINTS.ATTRITION.LOCATION}?Company_ID=${params.Company_ID}&Location_ID=${params.Location_ID}`,
                'GET'
            );
        },

        Get_Job_Attrition_Analysis: async (params: AnalyticsParams & { Department_ID: string }): Promise<AttritionAnalysisResult> => {
            if (!params.Company_ID || !params.Department_ID) {
                throw new Error('Company_ID and Department_ID are required');
            }

            return Make_Authorized_API_Call(
                `${ENDPOINTS.ATTRITION.JOB}?Company_ID=${params.Company_ID}&Department_ID=${params.Department_ID}`,
                'GET'
            );
        }
    },

    wage: {
        Get_Wage_Analysis: async (params: AnalyticsParams): Promise<AttritionAnalysisResult> => {
            if (!params.Company_ID) {
                throw new Error('Company_ID is required');
            }

            return Make_Authorized_API_Call(
                `${ENDPOINTS.WAGE.COMPANY}?Company_ID=${params.Company_ID}`,
                'GET'
            );
        }
    }
};
