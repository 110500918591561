import { Button, Dropdown } from 'flowbite-react';
import { memo, useCallback, useMemo, useState } from 'react';
import { FiClipboard, FiUpload } from 'react-icons/fi';
import { HiOutlineLogout } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { useStatus } from '../../../contexts/Status';
import { useClientManagement } from '../../../hooks/useClientManagement';
import { useWorkflowActions } from '../../../hooks/useWorkflowActions';
import type { Client, ClientCompany } from '../../../types/client';

const TopNavigation = memo(() => {
  const navigate = useNavigate();
  const { 
    Email_Address,
    Is_Super_Admin,
    Client_ID, 
    Company_ID,
    Client_Name,
    Company_Name, 
    Set_Client_Context,
    Clear_Client_Context,
    logout
  } = useStatus();
  const { Clients, Client_Companies, Is_Loading: Is_Client_Loading } = useClientManagement();
  const { Pending_Count } = useWorkflowActions();
  const [Is_Loading, Set_Is_Loading] = useState(false);

  const companies = useMemo(() => 
    Client_ID ? Client_Companies[Client_ID] || [] : [],
    [Client_ID, Client_Companies]
  );

  const Handle_Sign_Out = useCallback(async () => {
    Set_Is_Loading(true);
    try {
      await logout();
      Clear_Client_Context();
      navigate('/signin');
    } catch (error) {
      console.error('Failed to sign out:', error);
    } finally {
      Set_Is_Loading(false);
    }
  }, [Clear_Client_Context, navigate, logout]);

  const Handle_Client_Change = useCallback((client: Client) => {
    // When changing client, we pass null for company ID to clear company selection
    Set_Client_Context(client.Client_ID, '');
  }, [Set_Client_Context]);

  const Handle_Company_Change = useCallback((company: ClientCompany) => {
    // When selecting company, we pass both client and company IDs
    if (Client_ID) {
      Set_Client_Context(Client_ID, company.Company_ID);
    }
  }, [Set_Client_Context, Client_ID]);

  const Navigate_To_Onboarding = useCallback(() => navigate('/admin/onboarding'), [navigate]);
  const Navigate_To_Approvals = useCallback(() => navigate('/admin/approvals'), [navigate]);
  const Navigate_To_Super_Dashboard = useCallback(() => navigate('/super/dashboard'), [navigate]);
  const Navigate_To_Home = useCallback(() => navigate('/admin/dashboard'), [navigate]);

  return (
    <div className="flex justify-between items-center px-6 py-3 bg-white border-b shadow-sm">
      {/* Left section with logo and dropdowns */}
      <div className="flex items-center gap-6">
        <div className="cursor-pointer flex items-center" onClick={Navigate_To_Home}>
          <img src="../../assets/logo512.png" alt="Logo" className="h-8 w-auto" />
        </div>

        <div className="flex items-center gap-4">
          <span className="text-gray-500">
            Email: <span className="text-gray-900">{Email_Address || '-'}</span>
          </span>

          <div className="relative border-l pl-4">
            <div className="flex items-center gap-2">
              <Dropdown
                label={Client_Name || 'Select Client'}
                disabled={Is_Loading || Is_Client_Loading || Clients.length === 0}
              >
                {Clients.map((client: Client) => (
                  <Dropdown.Item
                    key={client.Client_ID}
                    onClick={() => Handle_Client_Change(client)}
                  >
                    {client.Client_Name}
                  </Dropdown.Item>
                ))}
              </Dropdown>

              <Dropdown
                label={Company_Name || 'Select Company'}
                disabled={Is_Loading || Is_Client_Loading || !Client_ID || companies.length === 0}
              >
                {companies.map((company: ClientCompany) => (
                  <Dropdown.Item
                    key={company.Company_ID}
                    onClick={() => Handle_Company_Change(company)}
                  >
                    {company.Company_Name}
                  </Dropdown.Item>
                ))}
              </Dropdown>
            </div>
          </div>
        </div>
      </div>

      {/* Right section with actions */}
      <div className="flex items-center gap-4">
        <button
          onClick={Navigate_To_Onboarding}
          title="Upload Data"
          className="p-2 text-gray-500 hover:text-gray-700 rounded-full hover:bg-gray-100"
        >
          <FiUpload className="h-5 w-5" />
        </button>

        <button
          onClick={Navigate_To_Approvals}
          title="Pending Approvals"
          className="p-2 text-gray-500 hover:text-gray-700 rounded-full hover:bg-gray-100 relative"
        >
          <FiClipboard className="h-5 w-5" />
          {Pending_Count > 0 && (
            <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
              {Pending_Count > 99 ? '99+' : Pending_Count}
            </span>
          )}
        </button>

        {Is_Super_Admin && (
          <button
            onClick={Navigate_To_Super_Dashboard}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
          >
            Super Dashboard
          </button>
        )}

        <Button
          color="gray"
          onClick={Handle_Sign_Out}
          disabled={Is_Loading}
        >
          <HiOutlineLogout className="h-5 w-5" />
        </Button>
      </div>
    </div>
  );
});

TopNavigation.displayName = 'TopNavigation';

export default TopNavigation;
