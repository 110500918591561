import type { Department } from '../../types/company';
import type { OrganizationNode } from '../../types/organization';
import { API_ENDPOINTS, Make_Authorized_API_Call } from "../core/apiClient";
import type { APIResult } from "../core/types";
import { isSuccessResponse } from "../core/types";

// Define endpoints with type assertions following CI standards
const ENDPOINTS = {
    BASE: (API_ENDPOINTS as any).COMPANY.ORGANIZATION.BASE as string,
    ALTERNATE: (API_ENDPOINTS as any).COMPANY.ORGANIZATION.ALTERNATE as string
} as const;

export const organizationApi = {
    Get_Organization: async (
        Company_ID: string
    ): Promise<OrganizationNode[]> => {
        if (!Company_ID) {
            throw new Error("Company_ID is required");
        }

        const response = await Make_Authorized_API_Call<APIResult<OrganizationNode>>(
            `${ENDPOINTS.BASE}?Company_ID=${Company_ID}`,
            'GET'
        );

        if (!isSuccessResponse(response)) {
            throw new Error(response.message || response.error_details || 'Failed to get organization');
        }

        // Type assertion since we know the response is successful
        return (response.data.Records as unknown) as OrganizationNode[];
    },

    Get_Departments: async (
        Company_ID: string
    ): Promise<Department[]> => {
        if (!Company_ID) {
            throw new Error("Company_ID is required");
        }

        const response = await Make_Authorized_API_Call<APIResult<Department>>(
            `${ENDPOINTS.ALTERNATE}?Company_ID=${Company_ID}`,
            'GET'
        );

        if (!isSuccessResponse(response)) {
            throw new Error(response.message || response.error_details || 'Failed to get departments');
        }

        // Type assertion since we know the response is successful
        return (response.data.Records as unknown) as Department[];
    },

    // Camel case aliases for backward compatibility
    getOrganization: async (Company_ID: string) =>
        organizationApi.Get_Organization(Company_ID),
    getDepartments: async (Company_ID: string) =>
        organizationApi.Get_Departments(Company_ID)
};
