import { CognitoUserPool } from 'amazon-cognito-identity-js';

let userPool: CognitoUserPool | null = null;

try {
  console.log('Initializing Cognito User Pool...');
  
  const userPoolId = process.env.REACT_APP_USER_POOL_ID;
  const clientId = process.env.REACT_APP_CLIENT_ID;

  console.log('Environment variables check:', {
    hasUserPoolId: !!userPoolId,
    hasClientId: !!clientId
  });

  if (!userPoolId || !clientId) {
    throw new Error(
      'Missing required Cognito configuration. Please ensure REACT_APP_USER_POOL_ID and REACT_APP_CLIENT_ID are set in your environment.'
    );
  }

  const poolData = {
    UserPoolId: userPoolId,
    ClientId: clientId,
    AuthenticationFlowType: 'USER_PASSWORD_AUTH'
  };

  userPool = new CognitoUserPool(poolData);
  console.log('Cognito User Pool initialized successfully');
} catch (error) {
  console.error('Failed to initialize Cognito User Pool:', error);
  // Don't throw here, let the Status provider handle the null userPool case
}

export default userPool;
