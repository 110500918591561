// Re-export from apiClient.ts for backward compatibility
export { API_ENDPOINTS } from './apiClient';

export const API_CONSTANTS = {
  DEFAULT_PAGE_SIZE: 10,
  MAX_PAGE_SIZE: 100,
  DEFAULT_SORT_DIRECTION: 'asc',
  DEFAULT_SORT_FIELD: 'Created_Date',
  STATUS: {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    PENDING: 'Pending',
    DELETED: 'Deleted'
  },
  ROLES: {
    ADMIN: 'Admin',
    MANAGER: 'Manager',
    APPROVER: 'Approver',
    AUDITOR: 'Auditor',
    HR: 'HR',
    EXECUTIVE: 'Executive',
    RECORDER: 'Recorder'
  },
  ACTION_TYPES: {
    CREATE: 'Create',
    UPDATE: 'Update',
    DELETE: 'Delete',
    APPROVE: 'Approve',
    REJECT: 'Reject'
  },
  ERROR_CODES: {
    UNAUTHORIZED: 'UNAUTHORIZED',
    FORBIDDEN: 'FORBIDDEN',
    NOT_FOUND: 'NOT_FOUND',
    VALIDATION_ERROR: 'VALIDATION_ERROR',
    INTERNAL_ERROR: 'INTERNAL_ERROR'
  }
} as const;
