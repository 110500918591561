import { useMemo } from 'react';
import { queryClient } from '../api/core/queryClient';

/**
 * Hook to access the singleton QueryClient instance
 * @returns The global QueryClient instance
 */
export const useQueryClient = () => {
  return useMemo(() => queryClient, []);
};
