import { API_ENDPOINTS, Make_Authorized_API_Call } from "../core";
import type { Wage_Analysis_Result, Wage_Record } from "./types";

// Define endpoints with type assertions following CI standards
const ENDPOINTS = {
    WAGE: {
        COMPANY: (API_ENDPOINTS as any).ANALYTICS.WAGE.COMPANY as string,
        DEPARTMENT: (API_ENDPOINTS as any).ANALYTICS.WAGE.DEPARTMENT as string,
        JOB: (API_ENDPOINTS as any).ANALYTICS.WAGE.JOB as string,
        LOCATION: (API_ENDPOINTS as any).ANALYTICS.WAGE.LOCATION as string
    }
} as const;

export const wageApi = {
    getCompanyWageAnalysis: async (Company_ID: string): Promise<Wage_Analysis_Result> => {
        return Make_Authorized_API_Call<Wage_Record>(
            `${ENDPOINTS.WAGE.COMPANY}?Company_ID=${Company_ID}`,
            'GET'
        );
    },

    getDepartmentWageAnalysis: async (Company_ID: string, Department_ID: string): Promise<Wage_Analysis_Result> => {
        return Make_Authorized_API_Call<Wage_Record>(
            `${ENDPOINTS.WAGE.DEPARTMENT}?Company_ID=${Company_ID}&Department_ID=${Department_ID}`,
            'GET'
        );
    },

    getJobWageAnalysis: async (Company_ID: string, Job_ID: string): Promise<Wage_Analysis_Result> => {
        return Make_Authorized_API_Call<Wage_Record>(
            `${ENDPOINTS.WAGE.JOB}?Company_ID=${Company_ID}&Job_ID=${Job_ID}`,
            'GET'
        );
    },

    getLocationWageAnalysis: async (Company_ID: string, Work_Location_ID: string): Promise<Wage_Analysis_Result> => {
        return Make_Authorized_API_Call<Wage_Record>(
            `${ENDPOINTS.WAGE.LOCATION}?Company_ID=${Company_ID}&Work_Location_ID=${Work_Location_ID}`,
            'GET'
        );
    }
};

export type { Wage_History, Wage_Record } from "./types";

