import React from 'react';

interface ErrorAlertProps {
  error: string;
  variant?: 'error' | 'warning' | 'info';
  title?: string;
}

const ErrorAlert: React.FC<ErrorAlertProps> = ({ error, variant = 'error', title }) => {
  const getVariantStyles = () => {
    switch (variant) {
      case 'error':
        return 'bg-red-100 border-red-400 text-red-700';
      case 'warning':
        return 'bg-yellow-100 border-yellow-400 text-yellow-700';
      case 'info':
        return 'bg-blue-100 border-blue-400 text-blue-700';
      default:
        return 'bg-red-100 border-red-400 text-red-700';
    }
  };

  return (
    <div className={`${getVariantStyles()} border px-4 py-3 rounded relative mb-4`} role="alert">
      {title && <strong className="font-bold mr-2">{title}</strong>}
      <span className="block sm:inline">{error}</span>
    </div>
  );
};

export default ErrorAlert;
