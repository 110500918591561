import type { Client, ClientCompany, CreateClientRequest, ValidClientType } from '../../types/client';
import { STATUS } from '../../types/status';
import type { UserEntitlement } from '../../types/user';
import { API_ENDPOINTS, Make_Authorized_API_Call } from "../core/apiClient";
import { API_Headers } from '../core/headers';
import { APIResult } from '../core/types';

// Define endpoints with type assertions following CI standards
const ENDPOINTS = {
  BASE: (API_ENDPOINTS as any).CLIENTS.BASE as string,
  COMPANIES: (API_ENDPOINTS as any).CLIENTS.COMPANIES as string,
  USER: (API_ENDPOINTS as any).USER.BASE as string,
  USER_ENTITLEMENTS: (API_ENDPOINTS as any).USER.ENTITLEMENTS as string
} as const;

// Valid client types from backend
const VALID_CLIENT_TYPES: ValidClientType[] = ['FLIGHT', 'RIGHTSIZE', 'PMS_ONLY', 'AGENTIC'];

// Base client following API contract
const BASE_CLIENT: CreateClientRequest = {
  Client_ID: '',
  Client_Name: '',
  Client_Type: 'FLIGHT' as ValidClientType,
  Client_Status: STATUS.ACTIVE
};

class ClientsAPI {
  async Get_Client_Users(Client_ID: string): Promise<APIResult<UserEntitlement>> {
    return Make_Authorized_API_Call<UserEntitlement>(
      ENDPOINTS.USER,
      'GET',
      undefined,
      {
        params: { Client_ID },
        headers: API_Headers.headers
      }
    );
  }

  async Delete_Client_Company(Client_ID: string, Company_ID: string): Promise<APIResult<unknown>> {
    return Make_Authorized_API_Call<unknown>(
      ENDPOINTS.COMPANIES,
      'DELETE',
      undefined,
      {
        params: { Client_ID, Company_ID },
        headers: API_Headers.headers
      }
    );
  }

  async Delete_User_Entitlement(Email_Address: string, Client_ID: string): Promise<APIResult<unknown>> {
    return Make_Authorized_API_Call<unknown>(
      ENDPOINTS.USER_ENTITLEMENTS,
      'DELETE',
      undefined,
      {
        params: { Email_Address, Client_ID },
        headers: API_Headers.headers
      }
    );
  }

  async Get_Clients(): Promise<APIResult<Client>> {
    return Make_Authorized_API_Call<Client>(
      ENDPOINTS.BASE,
      'GET',
      undefined,
      { headers: API_Headers.headers }
    );
  }

  /**
   * Creates a new client
   * 
   * Required fields:
   * - Client_ID: string
   * - Client_Name: string
   * 
   * Optional fields:
   * - Client_Type: 'FLIGHT' | 'RIGHTSIZE' | 'PMS_ONLY' | 'AGENTIC' (default: 'FLIGHT')
   * - Client_Status: 'Active' | 'Inactive' (default: 'Active')
   */
  async Create_Client(request: Partial<CreateClientRequest>): Promise<APIResult<Client>> {
    // Create request with defaults
    const createRequest: CreateClientRequest = {
      ...BASE_CLIENT,
      ...request
    };
    
    // Validate client type if provided
    if (createRequest.Client_Type && !VALID_CLIENT_TYPES.includes(createRequest.Client_Type)) {
      throw new Error(`Invalid client type. Must be one of: ${VALID_CLIENT_TYPES.join(', ')}`);
    }

    // Send request
    return Make_Authorized_API_Call<Client>(
      ENDPOINTS.BASE,
      'POST',
      createRequest,
      { headers: API_Headers.headers }
    );
  }

  async Update_Client(Client_ID: string, client: Partial<Client>): Promise<APIResult<Client>> {
    return Make_Authorized_API_Call<Client>(
      ENDPOINTS.BASE,
      'PUT',
      client,
      {
        params: { Client_ID },
        headers: API_Headers.headers
      }
    );
  }

  async Delete_Client(Client_ID: string): Promise<APIResult<unknown>> {
    return Make_Authorized_API_Call<unknown>(
      ENDPOINTS.BASE,
      'DELETE',
      undefined,
      {
        params: { Client_ID },
        headers: API_Headers.headers
      }
    );
  }

  async Get_Companies(Client_ID?: string): Promise<APIResult<ClientCompany>> {
    return Make_Authorized_API_Call<ClientCompany>(
      ENDPOINTS.COMPANIES,
      'GET',
      undefined,
      {
        params: Client_ID ? { Client_ID } : undefined,
        headers: API_Headers.headers
      }
    );
  }

  // Alias for backward compatibility
  async Get_Client_Companies(Client_ID: string): Promise<APIResult<ClientCompany>> {
    return this.Get_Companies(Client_ID);
  }

  async Create_Client_Company(company: Partial<ClientCompany>): Promise<APIResult<ClientCompany>> {
    return Make_Authorized_API_Call<ClientCompany>(
      ENDPOINTS.COMPANIES,
      'POST',
      company,
      { headers: API_Headers.headers }
    );
  }

  async Update_Client_Company(Company_ID: string, company: Partial<ClientCompany>): Promise<APIResult<ClientCompany>> {
    return Make_Authorized_API_Call<ClientCompany>(
      ENDPOINTS.COMPANIES,
      'PUT',
      company,
      {
        params: { Company_ID },
        headers: API_Headers.headers
      }
    );
  }
}

// Create instance with both naming conventions
const api = new ClientsAPI();

// Add camelCase methods for backward compatibility
interface BackwardCompatibleAPI extends ClientsAPI {
  getClientUsers: typeof ClientsAPI.prototype.Get_Client_Users;
  deleteClientCompany: typeof ClientsAPI.prototype.Delete_Client_Company;
  deleteUserEntitlement: typeof ClientsAPI.prototype.Delete_User_Entitlement;
  getClients: typeof ClientsAPI.prototype.Get_Clients;
  createClient: typeof ClientsAPI.prototype.Create_Client;
  updateClient: typeof ClientsAPI.prototype.Update_Client;
  deleteClient: typeof ClientsAPI.prototype.Delete_Client;
  getClientCompanies: typeof ClientsAPI.prototype.Get_Client_Companies;
  getCompanies: typeof ClientsAPI.prototype.Get_Companies;
  createClientCompany: typeof ClientsAPI.prototype.Create_Client_Company;
  updateClientCompany: typeof ClientsAPI.prototype.Update_Client_Company;
}

// Cast instance to include backward compatibility methods
export const clientsApi = api as BackwardCompatibleAPI;

// Assign backward compatibility methods
clientsApi.getClients = api.Get_Clients.bind(api);
clientsApi.createClient = api.Create_Client.bind(api);
clientsApi.updateClient = api.Update_Client.bind(api);
clientsApi.deleteClient = api.Delete_Client.bind(api);
clientsApi.getClientCompanies = api.Get_Client_Companies.bind(api);
clientsApi.createClientCompany = api.Create_Client_Company.bind(api);
clientsApi.getCompanies = api.Get_Companies.bind(api);
clientsApi.updateClientCompany = api.Update_Client_Company.bind(api);
clientsApi.getClientUsers = api.Get_Client_Users.bind(api);
clientsApi.deleteClientCompany = api.Delete_Client_Company.bind(api);
clientsApi.deleteUserEntitlement = api.Delete_User_Entitlement.bind(api);
