import { Spinner } from 'flowbite-react';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthenticatedApp from './app/AuthenticatedApp';
import { useStatus } from './contexts/Status';
import SignIn from "./features/auth/components/SignIn";

const AppRoutes: React.FC = () => {
  const { Auth_Status, Is_Loading } = useStatus();

  if (Is_Loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner size="xl" />
      </div>
    );
  }

  if (!Auth_Status) {
    return (
      <Routes>
        <Route path="/signin" element={<SignIn />} />
        <Route path="/" element={<Navigate to="/signin" replace />} />
        <Route path="*" element={<Navigate to="/signin" replace />} />
      </Routes>
    );
  }

  return <AuthenticatedApp />;
};

export default AppRoutes;
