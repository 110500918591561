import React from 'react';
import { useLocation } from 'react-router-dom';
import { AdminSecondaryNav, TopNavigation } from '../../../../components/navigation';
import type { MainLayoutProps } from './types';

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const location = useLocation();
  const showAdminNav = location.pathname.startsWith('/admin');
  const isSuperRoute = location.pathname.startsWith('/super');

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <header className="fixed top-0 w-full bg-white z-50">
        {/* Only show navigation in admin routes */}
        {showAdminNav && (
          <>
            <TopNavigation />
            <AdminSecondaryNav />
          </>
        )}
      </header>
      <main className={`container mx-auto px-4 sm:px-6 lg:px-8 ${showAdminNav ? 'pt-32' : 'pt-8'}`}>
        {children}
      </main>
    </div>
  );
};

export default MainLayout;
