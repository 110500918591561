import {
    API_ENDPOINTS,
    Make_Authorized_API_Call
} from "../core/apiClient";
import type { APIResult } from "../core/types";
import { isSuccessResponse } from "../core/types";
import type {
    Employee_Work_Style,
    GetAnalyticsParams,
    GetWorkStylesParams,
    WorkStyleAnalytics
} from "./types";

// Define endpoints with type assertions following CI standards
const ENDPOINTS = {
    WORKSTYLE: (API_ENDPOINTS as any).PMS.WORKSTYLE as string,
    METRICS: (API_ENDPOINTS as any).PMS.METRICS.COMPANY as string
} as const;

export const workStyleApi = {
    Get_Work_Style: async (Employee_ID: string): Promise<Employee_Work_Style[]> => {
        if (!Employee_ID) {
            throw new Error("Employee_ID is required");
        }

        const params = new URLSearchParams();
        params.append('Employee_ID', Employee_ID);

        const response = await Make_Authorized_API_Call<APIResult<Employee_Work_Style>>(
            `${ENDPOINTS.WORKSTYLE}?${params.toString()}`,
            'GET'
        );

        if (!isSuccessResponse(response)) {
            throw new Error(response.message || response.error_details || 'Failed to get work style');
        }

        // Type assertion since we know the response is successful
        return (response.data.Records as unknown) as Employee_Work_Style[];
    },

    Get_Work_Styles: async (params: GetWorkStylesParams): Promise<Employee_Work_Style[]> => {
        const queryParams = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
            if (value !== undefined) {
                queryParams.append(key, String(value));
            }
        });

        const response = await Make_Authorized_API_Call<APIResult<Employee_Work_Style>>(
            `${ENDPOINTS.WORKSTYLE}?${queryParams.toString()}`,
            'GET'
        );

        if (!isSuccessResponse(response)) {
            throw new Error(response.message || response.error_details || 'Failed to get work styles');
        }

        // Type assertion since we know the response is successful
        return (response.data.Records as unknown) as Employee_Work_Style[];
    },

    Get_Analytics: async (params: GetAnalyticsParams): Promise<WorkStyleAnalytics[]> => {
        const queryParams = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
            if (value !== undefined) {
                queryParams.append(key, String(value));
            }
        });

        const response = await Make_Authorized_API_Call<APIResult<WorkStyleAnalytics>>(
            `${ENDPOINTS.METRICS}?${queryParams.toString()}`,
            'GET'
        );

        if (!isSuccessResponse(response)) {
            throw new Error(response.message || response.error_details || 'Failed to get analytics');
        }

        // Type assertion since we know the response is successful
        return (response.data.Records as unknown) as WorkStyleAnalytics[];
    },

    Update_Work_Style: async (
        Employee_ID: string,
        data: Partial<Employee_Work_Style>
    ): Promise<Employee_Work_Style[]> => {
        if (!Employee_ID) {
            throw new Error("Employee_ID is required");
        }

        const response = await Make_Authorized_API_Call<APIResult<Employee_Work_Style>>(
            ENDPOINTS.WORKSTYLE,
            'PUT',
            { ...data, Employee_ID }
        );

        if (!isSuccessResponse(response)) {
            throw new Error(response.message || response.error_details || 'Failed to update work style');
        }

        // Type assertion since we know the response is successful
        return (response.data.Records as unknown) as Employee_Work_Style[];
    },

    Create_Work_Style: async (
        data: Omit<Employee_Work_Style, "Style_ID">
    ): Promise<Employee_Work_Style[]> => {
        const response = await Make_Authorized_API_Call<APIResult<Employee_Work_Style>>(
            ENDPOINTS.WORKSTYLE,
            'POST',
            data
        );

        if (!isSuccessResponse(response)) {
            throw new Error(response.message || response.error_details || 'Failed to create work style');
        }

        // Type assertion since we know the response is successful
        return (response.data.Records as unknown) as Employee_Work_Style[];
    },

    // Camel case aliases for backward compatibility
    getWorkStyle: async (Employee_ID: string) =>
        workStyleApi.Get_Work_Style(Employee_ID),
    getWorkStyles: async (params: GetWorkStylesParams) =>
        workStyleApi.Get_Work_Styles(params),
    getAnalytics: async (params: GetAnalyticsParams) =>
        workStyleApi.Get_Analytics(params),
    updateWorkStyle: async (Employee_ID: string, data: Partial<Employee_Work_Style>) =>
        workStyleApi.Update_Work_Style(Employee_ID, data),
    createWorkStyle: async (data: Omit<Employee_Work_Style, "Style_ID">) =>
        workStyleApi.Create_Work_Style(data)
};
