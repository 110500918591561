import { useState } from 'react';
import { clientsApi } from '../api/clients';
import { getRecordsFromResponse } from '../api/core/helpers';
import type { APIErrorResponse } from '../api/core/types';
import { isSuccessResponse } from '../api/core/types';
import type { Client, ClientCompany } from '../types/client';

interface Use_Client_Management_Return {
  Clients: Client[];
  Client_Companies: Record<string, ClientCompany[]>;
  Is_Loading: boolean;
  Error_Message: string | null;
  Fetch_Clients: () => Promise<Client[]>;
  Fetch_Companies: (Client_ID: string) => Promise<ClientCompany[]>;
}

export const useClientManagement = (): Use_Client_Management_Return => {
  const [Clients, Set_Clients] = useState<Client[]>([]);
  const [Client_Companies, Set_Client_Companies] = useState<Record<string, ClientCompany[]>>({});
  const [Is_Loading, Set_Is_Loading] = useState(false);
  const [Error_Message, Set_Error_Message] = useState<string | null>(null);

  const Fetch_Clients = async (): Promise<Client[]> => {
    try {
      Set_Is_Loading(true);
      Set_Error_Message(null);
      const response = await clientsApi.Get_Clients();
      
      if (!isSuccessResponse(response)) {
        throw new Error(response.message);
      }

      const clientList = getRecordsFromResponse(response);
      if (clientList.length === 0) {
        console.warn('No clients found');
      }
      Set_Clients(clientList);
      return clientList;
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message :
        (err as APIErrorResponse)?.message || 'Failed to fetch clients';
      console.error('Error fetching clients:', err);
      Set_Error_Message(errorMessage);
      throw err;
    } finally {
      Set_Is_Loading(false);
    }
  };

  const Fetch_Companies = async (Client_ID: string): Promise<ClientCompany[]> => {
    try {
      Set_Is_Loading(true);
      Set_Error_Message(null);
      const response = await clientsApi.Get_Client_Companies(Client_ID);
      
      if (!isSuccessResponse(response)) {
        throw new Error(response.message);
      }

      const companyList = getRecordsFromResponse(response);
      if (companyList.length === 0) {
        console.warn(`No companies found for client ${Client_ID}`);
      }
      Set_Client_Companies(prev => ({
        ...prev,
        [Client_ID]: companyList
      }));
      return companyList;
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message :
        (err as APIErrorResponse)?.message || 'Failed to fetch companies';
      console.error('Error fetching companies:', err);
      Set_Error_Message(errorMessage);
      throw err;
    } finally {
      Set_Is_Loading(false);
    }
  };

  return {
    Clients,
    Client_Companies,
    Is_Loading,
    Error_Message,
    Fetch_Clients,
    Fetch_Companies
  };
};
