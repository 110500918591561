/**
 * Money formatting utilities
 */

/**
 * Convert money string with $ and commas to number
 * @example "$2,355,200.00" -> 2355200.00
 */
export const formatMoney = (value: string): number => {
  return parseFloat(value.replace(/[$,]/g, ''));
};

/**
 * Format number as money string with $ and commas
 * @example 2355200.00 -> "$2,355,200.00"
 */
export const toMoneyString = (value: number): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(value);
};
