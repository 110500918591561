import axios, { type AxiosError, type AxiosResponse } from "axios";
import { API_Headers } from "./headers";
import type { APIErrorResponse, APIResponse, APIResult, RequestConfig } from "./types";

// Base URL configuration
const API_BASE = process.env.REACT_APP_API_URL?.replace(/\/+$/, '') + '/tim';

// API endpoints configuration
export const API_ENDPOINTS = {
    USER: {
        BASE: API_BASE + "/user",
        ENTITLEMENTS: API_BASE + "/user/entitle",
        APPROVER: API_BASE + "/user/approver"
    },
    CLIENTS: {
        BASE: API_BASE + "/clients",
        COMPANIES: API_BASE + "/clients/companies"
    },
    COMPANY: {
        BASE: API_BASE + "/company",
        ORGANIZATION: {
            BASE: API_BASE + "/company/org",
            ALTERNATE: API_BASE + "/org"
        },
        DEPARTMENT: {
            BASE: API_BASE + "/company/department",
            DETAIL: API_BASE + "/company/deptdetail"
        },
        LOCATION: {
            BASE: API_BASE + "/company/location",
            DETAIL: API_BASE + "/company/locationdetail"
        },
        JOB: {
            BASE: API_BASE + "/company/job",
            DETAIL: API_BASE + "/company/jobdetail"
        },
        MANAGER: {
            ASSIGNMENT: API_BASE + "/company/manager/assignment",
            SCORE: API_BASE + "/company/manager/score",
            SCOREBOARD: API_BASE + "/company/manager/scoreboard"
        },
        ACTION: {
            STATS: API_BASE + "/company/action/approval-stats",
            COMPLETED: API_BASE + "/company/action/completed-actions",
            PENDING: API_BASE + "/company/action/pending-actions",
            INITIATE: API_BASE + "/company/action/initiate",
            REJECT: API_BASE + "/company/action/reject",
            ADVANCE: API_BASE + "/company/action/update-and-advance"
        },
        EMAIL: {
            SEND_REJECTION: API_BASE + "/company/email/send-rejection"
        }
    },
    ANALYTICS: {
        WAGE: {
            COMPANY: API_BASE + "/wa/co",
            DEPARTMENT: API_BASE + "/wa/dept",
            JOB: API_BASE + "/wa/job",
            LOCATION: API_BASE + "/wa/loc"
        },
        ATTRITION: {
            COMPANY: API_BASE + "/attrit/co",
            DEPARTMENT: API_BASE + "/attrit/dept",
            JOB: API_BASE + "/attrit/job",
            LOCATION: API_BASE + "/attrit/loc"
        },
        TENURE: {
            COMPANY: API_BASE + "/ten/co",
            DEPARTMENT: API_BASE + "/ten/dept",
            JOB: API_BASE + "/ten/job",
            LOCATION: API_BASE + "/ten/loc"
        }
    },
    EMPLOYEE: {
        BASE: API_BASE + "/emp"
    },
    CONFIG: {
        ACTIONS: API_BASE + "/config/actions",
        APPROVALS: API_BASE + "/config/approvals"
    },
    APPROVAL_GROUP: {
        BASE: API_BASE + "/approvalgroup",
        EMAIL: API_BASE + "/approvalgroup/email"
    },
    EMAIL: {
        BASE: API_BASE + "/email",
        TEMPLATE: API_BASE + "/email/template",
        SEND: API_BASE + "/email/send",
        FEEDBACK: API_BASE + "/email/feedback"
    },
    LOAD: {
        CSV: API_BASE + "/load/csv",
        JSON: API_BASE + "/load/json",
        EMPLOYEE: API_BASE + "/load/emp",
        DELETE: {
            ROW_ID: API_BASE + "/load/delete/rowid",
            LOAD_ID: API_BASE + "/load/delete/loadid"
        },
        PROCESS: API_BASE + "/load/process"
    },
    PMS: {
        CAREER: API_BASE + "/pms/career",
        CONTRIBUTION: API_BASE + "/pms/contribution",
        WORKSTYLE: API_BASE + "/pms/workstyle",
        SUPERVISION: API_BASE + "/pms/supervision",
        REHIRE: API_BASE + "/pms/rehire",
        GUIDANCE: API_BASE + "/pms/guidance",
        IMPLEMENTATION: API_BASE + "/pms/implementation",
        INITIALIZE: API_BASE + "/pms/initialize",
        RECOMMENDATIONS: API_BASE + "/pms/recommendations",
        SUMMARY: API_BASE + "/pms/summary",
        METRICS: {
            CLIENT: API_BASE + "/pms/metrics/client",
            COMPANY: API_BASE + "/pms/metrics/company"
        },
        REGRETTABLE: API_BASE + "/pms/regrettable"
    }
} as const;

// API endpoints type definition
export type APIEndpoints = typeof API_ENDPOINTS;

/**
 * Transform raw API response to standardized format following API standards
 */
function transformResponse<T>(response: AxiosResponse): APIResponse<T> {
    // Transform data to ensure Records array
    const records = Array.isArray(response.data)
        ? response.data
        : response.data.Records
            ? response.data.Records
            : response.data.data?.Records
                ? response.data.data.Records
                : [response.data];

    // Extract or create Query_Context
    const queryContext = response.data.Query_Context || {
        Mode: response.config?.method?.toUpperCase(),
        Operation: response.config?.method === 'GET' ? 'READ' 
            : response.config?.method === 'POST' ? 'CREATE'
            : response.config?.method === 'PUT' ? 'UPDATE'
            : response.config?.method === 'DELETE' ? 'DELETE'
            : 'UNKNOWN',
        Company_ID: response.data.Company_ID,
        Period_Date: response.data.period_date,
        Request_ID: response.data.Request_ID || response.headers["x-request-id"],
        Timestamp: response.data.Timestamp || new Date().toISOString()
    };

    // Extract or create Analytics
    const analytics = response.data.Analytics || {
        Total_Active: records.filter((r: { Status?: string }) => r.Status === 'Active').length,
        By_Status: records.reduce((acc: Record<string, number>, r: { Status?: string }) => {
            const status = r.Status || 'Unknown';
            acc[status] = (acc[status] || 0) + 1;
            return acc;
        }, {}),
        Performance_Metrics: {
            Total_Duration_MS: response.data.duration_ms || 0,
            Average_Record_Duration_MS: response.data.duration_ms ? response.data.duration_ms / records.length : 0,
            Batches_Processed: 1
        }
    };

    return {
        success: true,
        message: response.data.message || "Operation successful",
        meta: {
            Total_Records: records.length,
            Request_ID: response.data.Request_ID || response.headers["x-request-id"] || "",
            Timestamp: response.data.Timestamp || new Date().toISOString(),
            Company_ID: response.data.Company_ID,
            period_date: response.data.period_date,
            last_update: response.data.last_update
        },
        data: {
            Records: records,
            Query_Context: queryContext,
            Analytics: analytics
        }
    };
}

/**
 * Make an authorized API call with proper error handling
 */
export async function Make_Authorized_API_Call<T>(
    endpoint: string,
    method: "GET" | "POST" | "PUT" | "DELETE",
    data?: unknown,
    config?: RequestConfig
): Promise<APIResult<T>> {
    console.log('[API] Making authorized call:', {
        endpoint,
        method,
        config: {
            ...config,
            headers: config?.headers ? 'Present' : 'Default'
        }
    });

    try {
        // Parse URL and query parameters
        const [baseEndpoint, queryString] = endpoint.split('?');
        
        // Normalize base endpoint URL
        const normalizedEndpoint = baseEndpoint.replace(/([^:]\/)\/+/g, "$1");

        // Parse and normalize query parameters
        let params = config?.params || {};
        if (queryString) {
            const searchParams = new URLSearchParams(queryString);
            searchParams.forEach((value, key) => {
                if (!params[key]) {
                    params[key] = value;
                }
            });
        }

        console.log('[API] Normalized request:', {
            url: normalizedEndpoint,
            params,
            method
        });

        const response = await axios({
            url: normalizedEndpoint,
            method,
            data,
            headers: config?.headers || API_Headers.headers,
            params
        });

        console.log('[API] Response received:', {
            status: response.status,
            recordCount: Array.isArray(response.data) ? response.data.length : 
                        response.data.Records ? response.data.Records.length :
                        response.data.data?.Records ? response.data.data.Records.length : 1,
            hasQueryContext: !!response.data.Query_Context,
            hasAnalytics: !!response.data.Analytics
        });

        const transformedResponse = transformResponse<T>(response);
        console.log('[API] Transformed response:', {
            success: transformedResponse.success,
            recordCount: transformedResponse.data.Records.length,
            meta: transformedResponse.meta
        });

        return transformedResponse;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            const axiosError = error as AxiosError<APIErrorResponse>;
            const errorResponse: APIErrorResponse = {
                success: false,
                message: axiosError.response?.data?.message || "API request failed",
                meta: {
                    Timestamp: new Date().toISOString(),
                    Request_ID: axiosError.response?.headers["x-request-id"],
                    Error_Source: "API"
                },
                error_code: axiosError.response?.data?.error_code || "UNKNOWN_ERROR",
                error_details: axiosError.response?.data?.error_details || axiosError.message,
                validation_errors: axiosError.response?.data?.validation_errors
            };

            // Add debug info in non-production
            if (process.env.NODE_ENV !== 'production') {
                errorResponse.debug = {
                    name: axiosError.name,
                    message: axiosError.message,
                    stack: axiosError.stack || ''
                };
            }

            console.error('[API] Axios error:', {
                status: axiosError.response?.status,
                statusText: axiosError.response?.statusText,
                errorCode: errorResponse.error_code,
                message: errorResponse.message
            });
            return errorResponse;
        }

        const errorResponse: APIErrorResponse = {
            success: false,
            message: "An unexpected error occurred",
            meta: {
                Timestamp: new Date().toISOString(),
                Error_Source: "System"
            },
            error_code: "UNKNOWN_ERROR",
            error_details: error instanceof Error ? error.message : "Unknown error"
        };

        // Add debug info in non-production
        if (process.env.NODE_ENV !== 'production' && error instanceof Error) {
            errorResponse.debug = {
                name: error.name,
                message: error.message,
                stack: error.stack || ''
            };
        }

            console.error('[API] Unexpected error:', {
                name: error instanceof Error ? error.name : 'Unknown',
                message: error instanceof Error ? error.message : 'Unknown error'
            });
            return errorResponse;
    }
}
