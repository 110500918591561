import { Spinner } from 'flowbite-react';
import React, { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Company_Chart_Data_Provider } from '../contexts/CompanyChartDataContext';
import { useStatus } from '../contexts/Status';
import MainLayout from '../shared/components/layout/MainLayout';

// Super admin pages
const SuperDashboard = lazy(() => import('../features/super/pages/Dashboard'));

// Core admin pages
const AdminDashboard = lazy(() => import('../features/admin/pages/AdminDashboard'));
const Organization = lazy(() => import('../features/admin/pages/Organization'));
const DashboardDepartment = lazy(() => import('../features/admin/pages/DashboardDepartment'));
const DashboardJob = lazy(() => import('../features/admin/pages/DashboardJob'));
const DashboardLocation = lazy(() => import('../features/admin/pages/DashboardLocation'));

// Action pages
const Actions = lazy(() => import('../features/actions/pages/Actions'));
const ActionConfig = lazy(() => import('../features/actions/pages/ActionConfig'));
const ApprovalGroups = lazy(() => import('../features/actions/pages/ApprovalGroups'));

// Analytics pages
const AnalyticsDashboard = lazy(() => import('../features/analytics/pages/AnalyticsDashboard'));
// TODO: Analytics pages to be implemented
// const DepartmentAnalytics = lazy(() => import('../features/analytics/pages/DepartmentAnalytics'));
// const JobAnalytics = lazy(() => import('../features/analytics/pages/JobAnalytics'));
// const LocationAnalytics = lazy(() => import('../features/analytics/pages/LocationAnalytics'));
// const OrganizationAnalytics = lazy(() => import('../features/analytics/pages/OrganizationAnalytics'));

// Workflow pages
const WorkflowApprovals = lazy(() => import('../features/workflow/pages').then(m => ({ default: m.Approvals })));
// TODO: Workflow pages to be implemented
// const WorkflowHistory = lazy(() => import('../features/workflow/pages/History'));
// const WorkflowTasks = lazy(() => import('../features/workflow/pages/Tasks'));

// Settings & Profile
const Profile = lazy(() => import('../features/admin/pages/Profile'));
const Settings = lazy(() => import('../features/admin/pages/Settings'));

const LoadingFallback: React.FC = () => (
  <div className="flex justify-center items-center h-screen">
    <Spinner size="xl" />
  </div>
);

const WelcomeScreen: React.FC = () => (
  <div className="flex items-center justify-center h-screen">
    <div className="text-center">
      <h1 className="text-2xl font-bold mb-4">Welcome</h1>
      <p>Please select a company to continue.</p>
    </div>
  </div>
);

const AuthenticatedApp: React.FC = () => {
  const { Is_Super_Admin, Company_ID } = useStatus();

  if (Is_Super_Admin) {
    return (
      <Suspense fallback={<LoadingFallback />}>
        <Routes>
          <Route path="/" element={<Navigate to="/super/dashboard" replace />} />
          <Route path="/super/dashboard" element={<SuperDashboard />} />
          <Route path="*" element={<Navigate to="/super/dashboard" replace />} />
        </Routes>
      </Suspense>
    );
  }

  if (!Is_Super_Admin && !Company_ID) {
    return <WelcomeScreen />;
  }

  return (
    <Company_Chart_Data_Provider>
      <MainLayout>
        <Suspense fallback={<LoadingFallback />}>
          <Routes>
            {/* Core Routes */}
            <Route path="/" element={<Navigate to="/admin/dashboard" replace />} />
            <Route path="/admin/dashboard" element={<AdminDashboard />} />
            <Route path="/admin/organization" element={<Organization />} />
            <Route path="/admin/departments" element={<DashboardDepartment />} />
            <Route path="/admin/department/:id" element={<DashboardDepartment />} />
            <Route path="/admin/locations" element={<DashboardLocation />} />
            <Route path="/admin/location/:id" element={<DashboardLocation />} />
            <Route path="/admin/jobs" element={<DashboardJob />} />
            <Route path="/admin/job/:id" element={<DashboardJob />} />
            
            {/* Action Routes */}
            <Route path="/admin/actions" element={<Actions />} />
            <Route path="/admin/action-config" element={<ActionConfig />} />
            <Route path="/admin/approval-groups" element={<ApprovalGroups />} />
            
            {/* Workflow Routes */}
            <Route path="/admin/workflow/approvals" element={<WorkflowApprovals />} />
            {/* TODO: Implement these workflow routes
            <Route path="/admin/workflow/tasks" element={<WorkflowTasks />} />
            <Route path="/admin/workflow/history" element={<WorkflowHistory />} />
            */}
            
            {/* Analytics Routes */}
            <Route path="/admin/analytics/dashboard" element={<AnalyticsDashboard />} />
            {/* TODO: Implement these analytics routes
            <Route path="/admin/analytics/organization" element={<OrganizationAnalytics />} />
            <Route path="/admin/analytics/department" element={<DepartmentAnalytics />} />
            <Route path="/admin/analytics/location" element={<LocationAnalytics />} />
            <Route path="/admin/analytics/jobs" element={<JobAnalytics />} />
            */}
            
            {/* Settings & Profile */}
            <Route path="/admin/settings" element={<Settings />} />
            <Route path="/admin/profile" element={<Profile />} />
            
            {/* Fallback */}
            <Route path="*" element={<Navigate to="/admin/dashboard" replace />} />
          </Routes>
        </Suspense>
      </MainLayout>
    </Company_Chart_Data_Provider>
  );
};

export default AuthenticatedApp;
