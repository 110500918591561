// Import APIs
import { actionApi } from './actions';
import { approvalApi } from './approvals';

// Export types
export * from './types';

// Export APIs
export { actionApi } from './actions';
export { approvalApi } from './approvals';

// Re-export commonly used types
export type {
    Action,
    Action_Params,
    Action_Result,
    Approval,
    Approval_Group,
    Approval_Group_Params,
    Approval_Group_Result,
    Approval_Params,
    Approval_Result
} from './types';

// Re-export status types
// Action_Status is an alias for Workflow_Status in the API layer
export type { Workflow_Status as Action_Status } from '../../types/status';

// Re-export config types
export type { Config_Action } from '../../types/action';

// Convenience API object
export const workflowModule = {
    actions: actionApi,
    approvals: approvalApi
};
