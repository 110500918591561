/**
 * Status Type Definitions
 * 
 * This file defines all status types used throughout the application.
 * Types are organized by domain and follow consistent naming patterns:
 * - Type names use PascalCase_With_Underscores
 * - Status values use underscores for multi-word values
 * - All types have corresponding type guards for runtime validation
 */

// Base Status (used by most entities)
export type Base_Status = "Active" | "Inactive";

// Maintain backward compatibility with PascalCase_With_Underscores
export type Status = Base_Status;
export type Entity_Status = Base_Status;

/**
 * User & Account Related Status Types
 */
export type User_Status = Base_Status | "Pending" | "Suspended";
export type Account_Status = boolean;  // Maps to active field in Users table
export type Employee_Status = Base_Status | "Terminated" | "On_Leave";

/**
 * Organization Status Types
 * All organization entities use Base_Status
 */
export type Company_Status = Base_Status;
export type Department_Status = Base_Status;
export type Location_Status = Base_Status;
export type Client_Status = Base_Status;

/**
 * Workflow & Approval Status Types
 * While DB uses boolean fields (Rejected, Completed), UI needs string representations
 */
export type Workflow_Status = 
    | "Not_Started"
    | "Pending"
    | "In_Progress" 
    | "Completed"
    | "Failed"
    | "Rejected"
    | "Cancelled";

export type Action_Status = Workflow_Status;

export type Approval_Step_Status = 
    | "Not_Started"
    | "In_Progress"
    | "Completed"
    | "Failed";

/**
 * Configuration & Action Types
 */
export type Config_Action_Status = Base_Status | "Deleted";
export type Config_Approval_Status = Base_Status | "Deleted";
export type Action_Category = "Reward" | "Replace" | "Remediate";

/**
 * Risk Assessment Types
 */
export type Risk_Level = "High" | "Medium" | "Low";

/**
 * Status Constants
 * Used throughout the application for consistent status values
 */
export const STATUS = {
    // Base statuses
    ACTIVE: "Active" as const,
    INACTIVE: "Inactive" as const,
    
    // Workflow statuses (UI representation of DB boolean fields)
    NOT_STARTED: "Not_Started" as const,
    PENDING: "Pending" as const,
    IN_PROGRESS: "In_Progress" as const,
    COMPLETED: "Completed" as const,
    FAILED: "Failed" as const,
    REJECTED: "Rejected" as const,
    CANCELLED: "Cancelled" as const,
    
    // Special statuses
    DELETED: "Deleted" as const,
    TERMINATED: "Terminated" as const,
    ON_LEAVE: "On_Leave" as const,
    SUSPENDED: "Suspended" as const
} as const;

// Type the STATUS object for better inference
type Status_Type = typeof STATUS;
export type Status_Keys = keyof Status_Type;
export type Status_Values = Status_Type[Status_Keys];

/**
 * Type Guards
 * Runtime validation for status types
 */

// Base status guard
export const isBaseStatus = (status: unknown): status is Base_Status => {
    return typeof status === "string" && ["Active", "Inactive"].includes(status);
};

// Workflow status guard
export const isWorkflowStatus = (status: unknown): status is Workflow_Status => {
    return typeof status === "string" && [
        "Not_Started",
        "Pending",
        "In_Progress",
        "Completed",
        "Failed",
        "Rejected",
        "Cancelled"
    ].includes(status);
};

// Action status guard (same as workflow)
export const isActionStatus = isWorkflowStatus;

// Approval step status guard
export const isApprovalStepStatus = (status: unknown): status is Approval_Step_Status => {
    return typeof status === "string" && [
        "Not_Started",
        "In_Progress",
        "Completed",
        "Failed"
    ].includes(status);
};

// Action category guard
export const isActionCategory = (category: unknown): category is Action_Category => {
    return typeof category === "string" && 
           ["Reward", "Replace", "Remediate"].includes(category);
};

// Risk level guard
export const isRiskLevel = (level: unknown): level is Risk_Level => {
    return typeof level === "string" && 
           ["High", "Medium", "Low"].includes(level);
};
