import { useEffect, useState } from 'react';
import { workflowModule } from '../api';
import { APIResponse, ErrorMessage, createErrorMessage } from '../api/core/types';
import { useStatus } from '../contexts/Status';
import type { Action } from '../types';

export const useWorkflowActions = () => {
  const [Pending_Count, Set_Pending_Count] = useState(0);
  const [Is_Loading, Set_Is_Loading] = useState(false);
  const [Error_Message, Set_Error_Message] = useState<ErrorMessage | null>(null);
  const [Meta, Set_Meta] = useState<APIResponse<Action>['meta'] | null>(null);
  const [Query_Context, Set_Query_Context] = useState<APIResponse<Action>['data']['Query_Context'] | null>(null);
  const [Analytics, Set_Analytics] = useState<APIResponse<Action>['data']['Analytics'] | null>(null);
  const { Company_ID, Email_Address } = useStatus();

  useEffect(() => {
    const Fetch_Pending_Count = async () => {
      if (!Company_ID || !Email_Address) {
        Set_Pending_Count(0);
        return;
      }

      try {
        Set_Is_Loading(true);
        Set_Error_Message(null);
        const response = await workflowModule.actions.Get_Actions({
          Company_ID,
          Active_Approver: Email_Address
        });
        
        if (!response.success) {
          throw new Error(response.message || response.error_details);
        }

        // Type assertion since we know the response is successful
        const successResponse = response as APIResponse<Action>;
        
        // Store meta information and data
        Set_Meta(successResponse.meta);
        Set_Query_Context(successResponse.data.Query_Context || null);
        Set_Analytics(successResponse.data.Analytics || null);
        Set_Pending_Count(successResponse.data.Records.length);
      } catch (err) {
        const errorMsg = createErrorMessage(err, 'Failed to fetch pending actions');
        console.error('Error fetching pending actions:', errorMsg);
        Set_Error_Message(errorMsg);
        Set_Pending_Count(0);
        Set_Meta(null);
        Set_Query_Context(null);
        Set_Analytics(null);
      } finally {
        Set_Is_Loading(false);
      }
    };

    Fetch_Pending_Count();
  }, [Company_ID, Email_Address]);

  return { 
    Pending_Count, 
    Is_Loading, 
    Error_Message,
    Meta,
    Query_Context,
    Analytics
  };
};
