import type { UserEntitlement } from "../../types/auth";
import type { User, UserRoles } from "../../types/user";
import { API_ENDPOINTS, Make_Authorized_API_Call } from "../core/apiClient";
import type { APIResult } from '../core/types';

// Re-export UserRoles type for backward compatibility
export type { UserRoles };

// Define endpoints with type assertions following CI standards
const ENDPOINTS = {
    BASE: (API_ENDPOINTS as any).USER.BASE as string,
    ENTITLEMENTS: (API_ENDPOINTS as any).USER.ENTITLEMENTS as string
} as const;

// Request Types
interface GetUsersParams {
    Email_Address?: string;
    [key: string]: unknown;
}

interface GetUserEntitlementsByEmailParams {
    Email_Address: string;
    [key: string]: unknown;
}

interface GetUserEntitlementsByCompanyParams {
    Company_ID: string;
    [key: string]: unknown;
}

interface CreateUserEntitlementParams {
    Email_Address: string;
    Client_ID: string;
    Role: string;
    [key: string]: unknown;
}

export const usersApi = {
    Get_Users: async (params?: GetUsersParams): Promise<APIResult<User>> => {
        const queryParams = new URLSearchParams();
        if (params?.Email_Address) queryParams.append('Email_Address', params.Email_Address);
        Object.entries(params || {}).forEach(([key, value]) => {
            if (value !== undefined && key !== 'Email_Address') {
                queryParams.append(key, String(value));
            }
        });

        return Make_Authorized_API_Call<User>(
            `${ENDPOINTS.BASE}?${queryParams.toString()}`,
            'GET'
        );
    },

    Get_User_Entitlements_By_Email: async (
        params: GetUserEntitlementsByEmailParams
    ): Promise<APIResult<UserEntitlement>> => {
        if (!params.Email_Address) {
            throw new Error("Email_Address is required");
        }

        const queryParams = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
            if (value !== undefined) {
                queryParams.append(key, String(value));
            }
        });

        return Make_Authorized_API_Call<UserEntitlement>(
            `${ENDPOINTS.ENTITLEMENTS}?${queryParams.toString()}`,
            'GET'
        );
    },

    Get_User_Entitlements_By_Company: async (
        params: GetUserEntitlementsByCompanyParams
    ): Promise<APIResult<UserEntitlement>> => {
        if (!params.Company_ID) {
            throw new Error("Company_ID is required");
        }

        const queryParams = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
            if (value !== undefined) {
                queryParams.append(key, String(value));
            }
        });

        return Make_Authorized_API_Call<UserEntitlement>(
            `${ENDPOINTS.ENTITLEMENTS}?${queryParams.toString()}`,
            'GET'
        );
    },

    Create_User_Entitlement: async (
        params: CreateUserEntitlementParams
    ): Promise<APIResult<UserEntitlement>> => {
        if (!params.Email_Address || !params.Client_ID || !params.Role) {
            throw new Error("Email_Address, Client_ID, and Role are required");
        }

        return Make_Authorized_API_Call<UserEntitlement>(
            ENDPOINTS.ENTITLEMENTS,
            'POST',
            params
        );
    },

    Update_User_Entitlement: async (
        Email_Address: string,
        params: Partial<CreateUserEntitlementParams>
    ): Promise<APIResult<UserEntitlement>> => {
        if (!Email_Address) {
            throw new Error("Email_Address is required");
        }

        return Make_Authorized_API_Call<UserEntitlement>(
            ENDPOINTS.ENTITLEMENTS,
            'PUT',
            { ...params, Email_Address }
        );
    },

    Delete_User_Entitlement: async (
        Email_Address: string,
        Client_ID: string
    ): Promise<APIResult<void>> => {
        if (!Email_Address || !Client_ID) {
            throw new Error("Email_Address and Client_ID are required");
        }

        const params = new URLSearchParams();
        params.append('Email_Address', Email_Address);
        params.append('Client_ID', Client_ID);

        return Make_Authorized_API_Call<void>(
            `${ENDPOINTS.ENTITLEMENTS}?${params.toString()}`,
            'DELETE'
        );
    },

    Create_User: async (userData: Partial<User>): Promise<APIResult<User>> => {
        if (!userData.Email_Address) {
            throw new Error("Email_Address is required");
        }

        return Make_Authorized_API_Call<User>(
            ENDPOINTS.BASE,
            'POST',
            userData
        );
    },

    Update_User: async (
        Email_Address: string,
        userData: Partial<User>
    ): Promise<APIResult<User>> => {
        if (!Email_Address) {
            throw new Error("Email_Address is required");
        }

        return Make_Authorized_API_Call<User>(
            ENDPOINTS.BASE,
            'PUT',
            { ...userData, Email_Address }
        );
    },

    Delete_User: async (Email_Address: string): Promise<APIResult<void>> => {
        if (!Email_Address) {
            throw new Error("Email_Address is required");
        }

        const params = new URLSearchParams();
        params.append('Email_Address', Email_Address);

        return Make_Authorized_API_Call<void>(
            `${ENDPOINTS.BASE}?${params.toString()}`,
            'DELETE'
        );
    },

    // Camel case aliases for backward compatibility
    getUsers: async (params?: GetUsersParams) =>
        usersApi.Get_Users(params),
    getUserEntitlementsByEmail: async (params: GetUserEntitlementsByEmailParams) =>
        usersApi.Get_User_Entitlements_By_Email(params),
    getUserEntitlementsByCompany: async (params: GetUserEntitlementsByCompanyParams) =>
        usersApi.Get_User_Entitlements_By_Company(params),
    createUserEntitlement: async (params: CreateUserEntitlementParams) =>
        usersApi.Create_User_Entitlement(params),
    updateUserEntitlement: async (Email_Address: string, params: Partial<CreateUserEntitlementParams>) =>
        usersApi.Update_User_Entitlement(Email_Address, params),
    deleteUserEntitlement: async (Email_Address: string, Client_ID: string) =>
        usersApi.Delete_User_Entitlement(Email_Address, Client_ID),
    createUser: async (userData: Partial<User>) =>
        usersApi.Create_User(userData),
    updateUser: async (Email_Address: string, userData: Partial<User>) =>
        usersApi.Update_User(Email_Address, userData),
    deleteUser: async (Email_Address: string) =>
        usersApi.Delete_User(Email_Address),
    // Legacy method for backward compatibility
    getUserEntitlements: async (params: GetUserEntitlementsByEmailParams | GetUserEntitlementsByCompanyParams) => {
        if (isEmailParams(params)) {
            return usersApi.Get_User_Entitlements_By_Email(params);
        }
        return usersApi.Get_User_Entitlements_By_Company(params);
    }
};

// Type guard for email params
function isEmailParams(params: GetUserEntitlementsByEmailParams | GetUserEntitlementsByCompanyParams): params is GetUserEntitlementsByEmailParams {
    return "Email_Address" in params;
}

// Export types and interfaces
export type {
    CreateUserEntitlementParams, GetUserEntitlementsByCompanyParams, GetUserEntitlementsByEmailParams, GetUsersParams
};
