import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorAlert from '../../../components/ErrorAlert';
import { useStatus } from '../../../contexts/Status';

interface SignInForm {
  email: string;
  password: string;
}

interface FormErrors {
  email?: string;
  password?: string;
}

const SignIn: React.FC = () => {
  const [formData, setFormData] = useState<SignInForm>({
    email: '',
    password: ''
  });
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const { authenticate, Is_Loading, Is_Super_Admin, Auth_Status, Error_Message } = useStatus();
  const navigate = useNavigate();

  // Handle navigation after successful authentication
  useEffect(() => {
    if (Auth_Status) {
      navigate(Is_Super_Admin ? '/super/dashboard' : '/admin/dashboard', { replace: true });
    }
  }, [Auth_Status, Is_Super_Admin, navigate]);
  const emailInputRef = useRef<HTMLInputElement>(null);

  // Focus email input on mount
  useEffect(() => {
    emailInputRef.current?.focus();
  }, []);

  const validateForm = (): boolean => {
    const errors: FormErrors = {};
    let isValid = true;

    if (!formData.email) {
      errors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Please enter a valid email address';
      isValid = false;
    }

    if (!formData.password) {
      errors.password = 'Password is required';
      isValid = false;
    }

    console.log('Form validation:', { formData, errors, isValid });
    setFormErrors(errors);
    return isValid;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user starts typing
    if (formErrors[name as keyof FormErrors]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: undefined
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Form submitted');
      if (Is_Loading) {
      console.log('Form submission blocked - loading');
      return;
    }

    if (!validateForm()) {
      console.log('Form validation failed');
      return;
    }

    try {
      console.log('Attempting authentication...');
      await authenticate(formData.email, formData.password);
      console.log('Authentication successful');
      // Navigation will be handled by useEffect
    } catch (err) {
      console.error('Authentication failed:', err);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto min-h-screen lg:py-0">
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            {/* Header */}
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white text-center">
              Clarity For Your <span className="text-indigo-600 dark:text-indigo-400">C-Suite</span>
            </h1>
            <p className="text-sm text-center text-gray-600 dark:text-gray-400">
              Ensuring consistent and transparent people operations in a qualitative world
            </p>

            <div className="mt-2 p-4 text-sm text-red-800 bg-red-50 dark:bg-gray-800 dark:text-red-400 rounded-lg text-center">
              Warning: This system is for authorized users only. Unauthorized access attempts are prohibited and may be subject to legal action.
            </div>

            {/* Error Alert */}
            {Error_Message && (
              <ErrorAlert
                error={Error_Message}
                variant="error"
                title="Authentication Error"
              />
            )}

            {/* Sign In Form */}
            <form 
              className="space-y-4 md:space-y-6" 
              onSubmit={handleSubmit}
              onKeyDown={handleKeyDown}
              noValidate
            >
              {/* Email Input */}
              <div>
                <label 
                  htmlFor="email-address" 
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Email address
                </label>
                <input
                  ref={emailInputRef}
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  placeholder="name@company.com"
                  value={formData.email}
                  onChange={handleChange}
                  disabled={Is_Loading}
                  className={`bg-gray-50 border ${formErrors.email ? 'border-red-500' : 'border-gray-300'} text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                />
                {formErrors.email && (
                  <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                    {formErrors.email}
                  </p>
                )}
              </div>

              {/* Password Input */}
              <div>
                <label 
                  htmlFor="password" 
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  placeholder="••••••••"
                  value={formData.password}
                  onChange={handleChange}
                  disabled={Is_Loading}
                  className={`bg-gray-50 border ${formErrors.password ? 'border-red-500' : 'border-gray-300'} text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                />
                {formErrors.password && (
                  <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                    {formErrors.password}
                  </p>
                )}
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                disabled={Is_Loading}
                className="w-full text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-indigo-600 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800"
              >
                {Is_Loading ? (
                  <>
                    <div className="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] mr-3" />
                    Signing in...
                  </>
                ) : (
                  'Sign in'
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignIn;
