import type { APIResult } from './types';
import { isSuccessResponse } from './types';

/**
 * Helper function to safely extract records from an API response
 * @param response The API response to extract records from
 * @returns The records array with proper typing
 * @throws Error if response is not successful
 */
export function getRecordsFromResponse<T>(response: APIResult<T>): T[] {
  if (!isSuccessResponse(response)) {
    throw new Error(response.message || 'API request failed');
  }
  return response.data.Records;
}
