import { Make_Authorized_API_Call } from '../core/apiClient';
import { API_ENDPOINTS } from '../core/constants';
import type { APIResult } from '../core/types';
import type { Approval_Group, Approval_Group_Params } from './types';

export const approvalApi = {
    Get_Approval_Groups: async (params: Approval_Group_Params): Promise<APIResult<Approval_Group>> => {
        const queryParams = new URLSearchParams();
        queryParams.append('Company_ID', params.Company_ID);
        if (params.Group_Name) queryParams.append('Group_Name', params.Group_Name);
        if (params.Status) queryParams.append('Status', params.Status);

        return Make_Authorized_API_Call(
            `${API_ENDPOINTS.APPROVAL_GROUP.BASE}?${queryParams.toString()}`,
            'GET'
        );
    }
};
